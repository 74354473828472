export const TEST_FAQ_LANGUAGE = {
  Carrier: [
    {
      question: "How can Genomic Life's Navigation team help me?",
      answer:
        "Genomic Life is here to support your health journey. Our expert navigation team can help you: <br/><br/> &#8226; Connect you with a genetic counselor or pharmacist.<br/> &#8226; Direct you to health resources.<br/> &#8226; Answer other questions you may have.<br/><br/>Contact us at <a href='mailto:navigation@genomiclife.com'>navigation@genomiclife.com</a>."
    },
    {
      question: "What is Carrier screening?",
      answer:
        "A carrier screen can tell you if you carry changes in your genes that you could pass to your children. These changes are not likely to affect your health but could increase the risk of your child inheriting a genetic condition. Carrier screening is most useful for family planning when both reproductive partners take the carrier screening test."
    },
    {
      question: "How do I speak with my healthcare provider about my results?",
      answer:
        "It is important to share your test results with your healthcare provider. Your healthcare provider can help you understand your results and what they mean for you and your partner for family planning purposes."
    },
    {
      question: "Should my partner have Carrier screening?",
      answer:
        "Carrier screening results are the most useful when both reproductive partners take the test. The test results can then be compared between the partners to determine if they both have a genetic change in the same gene. If there is a common finding in both partners' carrier screening reports, then the couple will be at a 25% risk of having a child with that disorder.<br/><br/>It is important to share your results with your healthcare provider. Genomic Life's member portal has simple steps to allow you to download your results. Genomic Life can schedule a telephone visit with a genetic counselor to talk about your results and what they mean for your family. Contact us at <a href='mailto:navigation@genomiclife.com'>navigation@genomiclife.com</a>"
    },
    {
      question: "Does Carrier Screening tell me about my health?",
      answer:
        "A carrier screening test generally does not tell you about your own personal health. It will look at whether you carry changes in your genes that you can pass down to each of your children. If you pass a genetic change down to your child, dependent on the status of your reproductive partner, your child may be at risk for inheriting a genetic condition.<br/><br/>Anyone who is pregnant, or planning to become pregnant, should consider having a carrier screening test. It is also important to have your reproductive partner get a carrier screening test as well."
    }
  ],
  PGx: [
    {
      question: "How can Genomic Life's Navigation team help me?",
      answer:
        "Genomic Life is here to support your health journey. Our expert navigation team can help you: <br/><br/> &#8226; Connect you with a genetic counselor or pharmacist.<br/> &#8226; Direct you to health resources.<br/> &#8226; Answer other questions you may have.<br/><br/>Contact us at <a href='mailto:navigation@genomiclife.com'>navigation@genomiclife.com</a>."
    },
    {
      question: "What is a Pharmacogenomic test?",
      answer:
        "A Pharmacogenomic (PGx) test is a test that looks at how your genes can affect the way your body reacts to and processes medications. Changes in your genes can influence how well a medication works for you or if it causes side effects. This information can help your healthcare provider tailor your medications based on your genes."
    },
    {
      question: "How do I speak with my healthcare provider about my results?",
      answer:
        "It is important to share your test results with your healthcare provider. Your healthcare provider can help you understand your results and what they mean for you. Based on talking with your healthcare provider, they may adjust your medication or dose based on the results from this pharmacogenomic test. These results may also be helpful if your healthcare provider wants to start you on any new medications.<br/><br/>Genomic Life's member portal has simple steps to allow you to download or print out your results so you may share them with your healthcare provider. This is a great way to start a conversation with your healthcare provider."
    },
    {
      question: "Will my results change my medications?",
      answer:
        "The results from your pharmacogenomic test could change how your healthcare provider prescribes your medication. If the results show that you could be at higher risk for adverse drug reactions, a pharmacist or your healthcare provider may adjust your medicine or the dose you take.<br/><br/>It is important to share your test results with your healthcare provider. Your healthcare provider can help you understand your results and what they mean for you. Genomic Life's member portal has simple steps to allow you to download your results so you may share them with your healthcare provider."
    }
  ],
  GHS: [
    {
      question: "How can Genomic Life's Navigation team help me?",
      answer:
        "Genomic Life is here to support your health journey. Our expert navigation team can help you: <br/><br/> &#8226; Connect you with a genetic counselor or pharmacist.<br/> &#8226; Direct you to health resources.<br/> &#8226; Answer other questions you may have.<br/><br/>Contact us at <a href='mailto:navigation@genomiclife.com'>navigation@genomiclife.com</a>."
    },
    {
      question: "What is the Genetic Health Screen?",
      answer:
        "The Genetic Health Screen (GHS) is a test that looks at parts of your DNA (genes).  It can give you and your healthcare provider useful information.  The test may tell you about whether you have an increased risk of developing a condition such as cancer, cardiovascular disease, or other disorders. The conditions tested for with this test may have medical steps or preventive measures you can take to stay healthy or catch the condition at an early, more treatable stage. The results should be shared with your healthcare provider."
    },
    {
      question: "What are the possible results?",
      answer:
        "The possible results from a Genetic Health Screen can be positive, negative, or carrier.<br/><br/>A positive result means the test identified an important finding. The positive gene results may increase your risk of having the related condition. The result does not mean you are diagnosed with the disorder or that you will definitely develop the disorder. You should speak with a genetic specialist, such as a genetic counselor, or healthcare provider. This will help you understand the result and what it means for your and your family's health.<br/><br/>A negative result means no significant finding was identified in your genes that were tested. Your risk for disease is influenced by your lifestyle, personal, environmental, and other genetic risk factors not tested for. It is important to share your results with your healthcare provider to help you develop a health plan to stay healthy.<br/><br/>A carrier result means no significant finding was identified that is likely to affect your health. However, a finding was identified that may increase your risk of having a child with a condition. Your results and your reproductive partner's results can be used to help with family planning. It is important to share your results with your healthcare provider to help you develop a plan to stay healthy."
    },
    {
      question: "How do I speak with my healthcare provider about my results?",
      answer:
        "It is important to share your test results with your healthcare provider. Your healthcare provider can help you understand your results and what they mean for you. Your healthcare provider can also talk about any impact your results may have on your family, and what your next steps may be to keep you healthy. It is important to stay up to date with routine screening and health check guidelines.<br/><br/>Genomic Life's member portal has simple steps to allow you to download or print out your results so you may share them with your healthcare provider. This is a great way to start a conversation with your healthcare provider."
    },
    {
      question: "What are my next steps if my result is Positive?",
      answer:
        "If your results are positive, you may have questions and concerns about them. We recommend that you:<br/><br/> &#8226; Download your results.<br/> &#8226; Share them with your healthcare provider.<br/> &#8226; Discuss actions you can take.<br/> &#8226; Develop a plan.<br/><br/>You may also want to speak with a genetic counselor about your results. Genomic Life can schedule a telephone visit with a genetic counselor to talk about your results and important next steps.  Contact us at <a href='mailto:navigation@genomiclife.com'>navigation@genomiclife.com</a>."
    },
    {
      question: "What are my next steps if my result is Negative?",
      answer:
        "If your results are negative, you may question what they mean. We recommend that you share your results with your healthcare provider. You may have other risks in your lifestyle, personal, environmental, or other genetic risk factors that affect your health.  Genomic Life encourages you to create an action plan with your healthcare provider to stay healthy, including age and sex-appropriate health monitoring and routine screenings."
    }
  ]
};

export const TEST_DESCRIPTION_LANGUAGE = {
  Carrier:
    "This test helps with family planning for you and your partner to understand changes in your genes that don't affect you but may affect your child.",
  PGx:
    "This test provides information that can help you and your provider choose the medication and dosage that's right for you.",
  GHS:
    "This medical-grade test can reveal your risk for developing certain kinds of cancer, heart disease, and other actionable conditions."
};

export const TEST_TYPE_BY_UUID = {
  "49980d6c-bc0e-43c0-8139-150c4d47ccd9": "Carrier",
  "dna-visit-carrier": "Carrier",
  "ea4dd20c-52b8-4e3d-9fdb-9796bc4a50a8": "PGx", // - OneOme
  "8845bdbe-330e-4afc-82ed-f1cfa1d808ad": "PGx", // - Invitae
  "71957dc2-ee1e-4d9d-bc72-507aeb0ff992": "PGx", // - Legacy testing uuid
  "429fc77b-c1c1-455e-937c-de67db27e058": "PGx", // - Legacy testing uuid
  "dna-visit-pgx": "PGx",
  "3cf65de6-41f9-4e98-a256-231c871e3377": "GHS",
  "dna-visit-ghs": "GHS"
};

export const getFaqLanguageForTestByTestUUID = (testUUID) => {
  return TEST_FAQ_LANGUAGE[TEST_TYPE_BY_UUID[testUUID]];
};

export const getDescriptionLanguageForTestByTestUUID = (testUUID) => {
  return TEST_DESCRIPTION_LANGUAGE[TEST_TYPE_BY_UUID[testUUID]];
};

export const getDescriptionLanguageForTestByTestDescription = (description) => {
  if (description.uuid) {
    return getDescriptionLanguageForTestByTestUUID(description.uuid);
  } else {
    return TEST_DESCRIPTION_LANGUAGE[description.name];
  }
};

export const getFaqLanguageForTestByTestDescription = (description) => {
  if (description.uuid) {
    return getFaqLanguageForTestByTestUUID(description.uuid);
  } else {
    return TEST_FAQ_LANGUAGE[description.name];
  }
};
