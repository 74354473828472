import React, { ReactNode, useEffect, useState } from "react";
import {
  TextField,
  Box,
  Typography,
  IconButton,
  keyframes
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GLTooltip from "components/v3/sharedComponents/GLTooltip";
import { COLORS } from "components/v3/Theme/colors";

// There is a lot of bizwack in here around validation to support requests
//  from design. They want the field not to light up invalid until a user
//  has focused and defocused it, but they still want the save button
//  disabled. So the validity we report to the form that drives the button
//  disable is more strict than the validity the field itself shows, since
//  it may not show invalid if you are working on it or have not focused it before.
//  It's brittle. Be careful with it.

interface GLTextFieldBaseProps {
  label: string;
  tooltip?: string | ReactNode;
  value?: string;
  defaultValue?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  additionalValidation?: (value?: string) => string | undefined;
  maxLength?: number;
}

// When `disabled` is false or not set, `id` and `onChange` are required
interface GLTextFieldEnabledProps extends GLTextFieldBaseProps {
  disabled?: false;
  id: string;
  onChange: (data: {
    e: React.ChangeEvent<HTMLInputElement>;
    isInvalid: boolean;
  }) => void;
}

// When `disabled` is true, `id` and `onChange` are optional
interface GLTextFieldDisabledProps extends GLTextFieldBaseProps {
  disabled: true;
  id?: string;
  onChange?: (data: {
    e: React.ChangeEvent<HTMLInputElement>;
    isInvalid: boolean;
  }) => void;
}

export type GLTextFieldProps =
  | GLTextFieldEnabledProps
  | GLTextFieldDisabledProps;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const GLTextField: React.FC<GLTextFieldProps> = ({
  label,
  id,
  onChange,
  tooltip,
  value,
  defaultValue,
  required,
  disabled,
  placeholder,
  additionalValidation,
  maxLength = 200
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  const [hasReceivedFocus, setHasReceivedFocus] = useState(false);

  useEffect(() => {
    if (onChange) {
      onChange({
        e: { target: { name: id, value } } as React.ChangeEvent<
          HTMLInputElement
        >,
        isInvalid: !!calculatePossibleHelperText(value)
      });
    }
  }, [hasReceivedFocus]); // eslint-disable-line

  useEffect(() => {
    // Remove leading and trailing spaces when input changes focus
    // Replace multiple spaces with a single space
    if (onChange) {
      const trimmedValue: string = value
        ? value.trim().replace(/\s+/g, " ")
        : "";
      onChange({
        e: {
          target: { name: id, value: trimmedValue }
        } as React.ChangeEvent<HTMLInputElement>,
        isInvalid: !!calculatePossibleHelperText(value)
      });
    }
  }, [hasFocus]); // eslint-disable-line

  const calculatePossibleHelperText = (
    valueToValidate: string
  ): string | null => {
    if (required && !valueToValidate) {
      return "This field is required";
    }
    if (valueToValidate?.length > maxLength) {
      return `Exceeds ${maxLength} characters`;
    }
    if (additionalValidation && additionalValidation(valueToValidate)) {
      return additionalValidation(valueToValidate);
    }
    return null;
  };

  const shouldBeShowingErrorState =
    !hasFocus &&
    ((value && !hasReceivedFocus) || hasReceivedFocus) &&
    !!calculatePossibleHelperText(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.slice(0, maxLength); // Limit the length
    if (onChange) {
      onChange({
        e,
        isInvalid: !!calculatePossibleHelperText(e.target.value)
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%"
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex"
        }}
      >
        <Typography variant="body_strong_black">{label}</Typography>

        {!required && (
          <Typography
            variant="body_xs_dark_gray"
            sx={{
              marginLeft: "12px",
              marginTop: "3px",
              fontWeight: 400,
              fontStyle: "italic"
            }}
          >
            (Optional)
          </Typography>
        )}

        {tooltip && (
          <GLTooltip title={tooltip}>
            <IconButton size="small" sx={{ padding: 0, ml: "10px" }}>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </GLTooltip>
        )}
      </Box>

      <TextField
        fullWidth
        id={id}
        name={id}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        variant="outlined"
        required={required}
        disabled={disabled}
        error={shouldBeShowingErrorState}
        helperText={
          shouldBeShowingErrorState ? calculatePossibleHelperText(value) : null
        }
        onChange={handleChange}
        onFocus={() => {
          setHasFocus(true);
        }}
        onBlur={() => {
          setHasReceivedFocus(true);
          setHasFocus(false);
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            position: "relative",
            backgroundColor: disabled ? COLORS.PRIMARY_LIGHTEST : "transparent",
            transition: "border-color 0.3s ease, background-color 0.3s ease",
            "& fieldset": {
              borderColor: COLORS.PRIMARY_LIGHTER,
              transition: "border-color 0.3s ease, background-color 0.3s ease"
            },
            "&:hover fieldset": {
              borderColor: COLORS.PRIMARY,
              transition: "border-color 0.3s ease, background-color 0.3s ease"
            },
            "&.Mui-focused fieldset": {
              borderColor: COLORS.PRIMARY_DARK,
              transition: "border-color 0.3s ease, background-color 0.3s ease"
            },
            "&.Mui-disabled": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: COLORS.PRIMARY_LIGHTER
              },
              backgroundColor: COLORS.PRIMARY_LIGHTEST,
              color: COLORS.DARK_GRAY,
              "& .MuiOutlinedInput-input": {
                color: COLORS.DARK_GRAY
              }
            }
          },
          "& .MuiOutlinedInput-input": {
            padding: "12px 16px 12px 20px",
            display: "flex",
            alignItems: "center",
            fontSize: "16px"
          },
          "& .MuiFormHelperText-root": {
            position: "relative",
            width: "fit-content",
            top: "-13px",
            left: "14px",
            height: "13px",
            marginTop: 0,
            marginBottom: "-13px",
            paddingLeft: "5px",
            paddingRight: "5px",
            backgroundColor: COLORS.WHITE,
            opacity: 0,
            animation: `${fadeIn} 0.3s forwards`
          }
        }}
      />
    </Box>
  );
};

export default GLTextField;
