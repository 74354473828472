// React and Core Libraries
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

// Authentication
import LoginPage from "components/v2/Pages/LoginPage";
import LoginCallbackPage from "components/v2/Pages/LoginPage/LoginCallbackPage";
import ScrapeApiPage from "components/v2/Pages/LoginPage/ScrapeApiPage";
import LogoutPage from "components/v2/Pages/LogoutPage";
import RequestPasswordResetEmailPage from "components/pages/RequestPasswordResetEmailPage";
import RequestPasswordResetEmailFailurePage from "components/pages/RequestPasswordResetEmailFailurePage";
import RequestPasswordResetEmailSuccessPage from "components/pages/RequestPasswordResetEmailSuccessPage";

// Onboarding and Welcome
import WelcomePage from "components/v3/pages/onboarding/WelcomePage";
import RegistrationInstructions from "components/v3/pages/onboarding/RegistrationInstructions";
import GettingStartedPage from "components/v3/pages/onboarding/GettingStartedPage";
import TermsPage from "components/v3/pages/onboarding/TermsPage";
import ProfilePage from "components/v3/pages/onboarding/ProfilePage";

// Dashboard
import DashboardPage from "components/v2/Pages/DashboardPage";

// Account Management
import AccountManagementPage from "components/pages/AccountManagementPage";
import AccountManagementMFAPage from "components/pages/AccountManagementPage/AccountManagementMFAPage";
import AccountManagementMemberInfoPage from "components/pages/AccountManagementPage/AccountManagementMemberInfoPage";
import AccountManagementPrivacySecurityPage from "components/pages/AccountManagementPage/AccountManagementPrivacySecurityPage";
import AccountManagementTermsOfService from "components/pages/AccountManagementPage/AccountManagementTermsOfService";
import ConnectionsPage from "components/pages/AccountManagementPage/ConnectionsPage";
import ConnectionDetailsPage from "components/pages/AccountManagementPage/ConnectionsPage/ConnectionDetailsPage";
import PaymentInformation from "components/pages/AccountManagementPage/PaymentInformation";
import SetNotificationPage from "components/pages/AccountManagementPage/SetNotificationPage";
import ChangePassword from "components/pages/ChangePasswordPage";

// Survey Pages
import GettingStartedSurveyPage from "components/v2/Pages/GettingStartedSurveyPage";
import CancerSurveyPage from "components/v2/Pages/CancerSurveyPage";
import InformedConsentSurveyPage from "components/v2/Pages/InformedConsentSurveyPage";

// Kit Ordering
import OrderingPage from "components/pages/OrderingPage";
import OrderKitConclusionPage from "components/pages/OrderingPage/OrderKitConclusionPage";
import TestKitSelectionPage from "components/v2/Pages/TestKitSelectionPage";

// Reporting and Results
import ReportingSharingPage from "components/pages/ReportingSharingPage/ReportingSurvey";
import TransmissionConclusionPage from "components/pages/ReportingSharingPage/ReportingSurvey/TransmissionConclusionPage";
import TestResultsPage from "components/v2/Pages/TestResultsPage";
import TestResultDocumentPage from "components/v2/Pages/TestResultDocumentPage";

// CMS Controlled Pages
import HelpCenterPage from "components/v2/CMSDriven/Pages/HelpCenter";

// Utility Pages
import MaintenancePage from "components/v2/Pages/MaintenancePage";
import LoggedOutPage from "components/v2/Pages/LoggedOutPage";
import IdleTimer from "components/SessionTimer/IdleTimer";

// Utility and Constants
import { PATHS } from "./constants/pathConstants";
import { getCookie } from "./utils/CookieUtil";
import { validateToken } from "./utils/utils";

// Redux Selectors
import {
  selectUserHasAcceptedAllCurrentAgreements,
  selectUserHasNeverAcceptedSomeAgreements
} from "./store/reducers/termsOfService/TermsOfServiceSlice";
import {
  selectGreatInitialApiScrapeIsComplete,
  selectUserPersonalInformation
} from "./store/reducers/user/UserAccountSlice";
import ScrollToTop from "components/ScrollToTop";
import TermsOfServicePage from "components/pages/TermsOfServicePage";
import TermsOfServiceReviewPage from "components/pages/TermsOfServiceReviewPage";

export const PAGES_ALLOWED_TO_UNAUTHENTICATED_USERS = [
  PATHS.MAINTENANCE,
  PATHS.ONBOARDING_WELCOME,
  PATHS.ONBOARDING_REGISTRATION_INSTRUCTIONS,
  PATHS.LOGIN,
  PATHS.LOGIN_CALLBACK,
  PATHS.API_SCRAPE,
  PATHS.LOG_OUT,
  PATHS.LOGGED_OUT,
  PATHS.REQUEST_PASSWORD_EMAIL,
  PATHS.REQUEST_PASSWORD_EMAIL_SUCCESS,
  PATHS.REQUEST_PASSWORD_EMAIL_FAILURE
];

export const PAGES_FORBIDDEN_TO_LOGGED_IN_USERS = [PATHS.ONBOARDING_WELCOME];

const PAGES_FORBIDDEN_TO_INACTIVE_USERS = [
  PATHS.ORDER_KIT,
  PATHS.ORDER_KIT_SELECTION,
  PATHS.ORDER_KIT_CONFIRMATION,
  PATHS.GETTING_STARTED_SURVEY,
  PATHS.CANCER_SURVEY
];

const TERMS_AND_CONDITION_PAGES = [
  PATHS.ONBOARDING_GETTING_STARTED,
  PATHS.ONBOARDING_TERMS,
  PATHS.ONBOARDING_PROFILE,
  PATHS.LOG_OUT
];

function AppRouter(props) {
  const { REACT_APP_UNDER_MAINTENANCE } = process.env;

  const visitorIsAMember = !!useSelector(selectUserPersonalInformation)
    .glMemberId;

  const isMemberActive = useSelector(selectUserPersonalInformation)
    .isMemberActive;

  const userNeedsToBeOnboarded = useSelector(
    selectUserHasNeverAcceptedSomeAgreements
  );

  const allAgreementsCurrent = useSelector(
    selectUserHasAcceptedAllCurrentAgreements
  );

  const greatInitialApiScrapeIsComplete = useSelector(
    selectGreatInitialApiScrapeIsComplete
  );

  const sessionIsValid = validateToken(getCookie("accessToken"));

  // There are a few possible states related to authentication here,
  //  and each must be re-renderable as many times as is needed, since
  //  there are multiple dispatches and redirects in the process.
  // These tests are the gatekeepers. This will need refactoring if it
  //  gets much more complicated.

  if (
    REACT_APP_UNDER_MAINTENANCE &&
    window.location.pathname !== PATHS.MAINTENANCE
  ) {
    return <Redirect to={PATHS.MAINTENANCE} />;
  }

  if (
    (!sessionIsValid || !greatInitialApiScrapeIsComplete) &&
    !PAGES_ALLOWED_TO_UNAUTHENTICATED_USERS.includes(window.location.pathname)
  ) {
    return <Redirect to={PATHS.LOG_OUT} />;
  }

  if (sessionIsValid && !visitorIsAMember) {
    //return <Redirect to={PATHS.LOG_OUT} />;
  }

  if (
    sessionIsValid &&
    greatInitialApiScrapeIsComplete &&
    userNeedsToBeOnboarded &&
    !TERMS_AND_CONDITION_PAGES.includes(window.location.pathname)
  ) {
    return <Redirect to={PATHS.ONBOARDING_GETTING_STARTED} />;
  }

  if (
    sessionIsValid &&
    greatInitialApiScrapeIsComplete &&
    !userNeedsToBeOnboarded &&
    !allAgreementsCurrent &&
    ![PATHS.ACCOUNT_MANAGEMENT_UPDATE_AGREEMENTS].includes(
      window.location.pathname
    )
  ) {
    return <Redirect to={PATHS.ACCOUNT_MANAGEMENT_UPDATE_AGREEMENTS} />;
  }
  if (
    !isMemberActive &&
    PAGES_FORBIDDEN_TO_INACTIVE_USERS.includes(window.location.pathname)
  ) {
    return <Redirect to={PATHS.LANDING_PAGE} />;
  }

  if (
    sessionIsValid &&
    PAGES_FORBIDDEN_TO_LOGGED_IN_USERS.includes(window.location.pathname)
  ) {
    return <Redirect to={PATHS.LANDING_PAGE} />;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path={PATHS.LANDING_PAGE}>
          <DashboardPage exact path={PATHS.LANDING_PAGE} />
        </Route>
        {/* Authentication */}
        <Route exact path={PATHS.API_SCRAPE}>
          <ScrapeApiPage {...props} />
        </Route>
        <Route exact path={PATHS.LOGIN}>
          <LoginPage {...props} />
        </Route>
        <Route exact path={PATHS.LOGIN_CALLBACK}>
          <LoginCallbackPage {...props} />
        </Route>
        <Route exact path={PATHS.LOGGED_OUT}>
          <LoggedOutPage {...props} />
        </Route>
        <Route exact path={PATHS.LOG_OUT}>
          <LogoutPage {...props} />
        </Route>
        <Route exact path={PATHS.REQUEST_PASSWORD_EMAIL}>
          <RequestPasswordResetEmailPage {...props} />
        </Route>
        <Route exact path={PATHS.REQUEST_PASSWORD_EMAIL_FAILURE}>
          <RequestPasswordResetEmailFailurePage {...props} />
        </Route>
        <Route exact path={PATHS.REQUEST_PASSWORD_EMAIL_SUCCESS}>
          <RequestPasswordResetEmailSuccessPage {...props} />
        </Route>
        {/* Onboarding */}
        <Route exact path={PATHS.ONBOARDING_WELCOME}>
          <WelcomePage {...props} />
        </Route>
        <Route exact path={PATHS.ONBOARDING_REGISTRATION_INSTRUCTIONS}>
          <RegistrationInstructions {...props} />
        </Route>

        <Route exact path={PATHS.ONBOARDING_GETTING_STARTED}>
          <GettingStartedPage {...props} />
        </Route>
        <Route exact path={PATHS.ONBOARDING_TERMS}>
          <TermsPage {...props} />
        </Route>
        <Route exact path={PATHS.ONBOARDING_PROFILE}>
          <ProfilePage {...props} />
        </Route>
        {/* Account Management */}
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT}>
          <AccountManagementPage {...props} />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_CONNECTIONS}>
          <ConnectionsPage
            {...props}
            goBackPath={PATHS.ACCOUNT_MANAGEMENT}
            goBackTitle="Account Management"
          />
        </Route>
        <Route
          exact
          path={PATHS.ACCOUNT_MANAGEMENT_CONNECTIONS_CONNECTION_DETAILS}
        >
          <ConnectionDetailsPage
            {...props}
            goBackPath={PATHS.ACCOUNT_MANAGEMENT_CONNECTIONS}
            goBackTitle="Connections"
          />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_MEMBER_INFORMATION}>
          <AccountManagementMemberInfoPage {...props} />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_MFA}>
          <AccountManagementMFAPage {...props} />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_NOTIFICATIONS_SETTINGS}>
          <SetNotificationPage backTo={PATHS.ACCOUNT_MANAGEMENT} {...props} />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_PAYMENT_INFORMATION}>
          <PaymentInformation {...props} />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_UPDATE_AGREEMENTS}>
          <TermsOfServicePage {...props} />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_PRIVACY_AND_SECURITY}>
          <AccountManagementPrivacySecurityPage {...props} />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_AGREEMENTS}>
          <AccountManagementTermsOfService {...props} />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_RESET_PASSWORD}>
          <ChangePassword
            backTo={PATHS.ACCOUNT_MANAGEMENT_PRIVACY_AND_SECURITY}
            {...props}
          />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_PRIVACY_POLICY}>
          <TermsOfServiceReviewPage
            {...props}
            title={"Privacy Policy"}
            agreementKey={"privacyPolicy"}
          />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_TERMS_AND_CONDITIONS}>
          <TermsOfServiceReviewPage
            {...props}
            title={"Terms & Conditions"}
            agreementKey={"termsAndConditions"}
          />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_HIPAA}>
          <TermsOfServiceReviewPage
            {...props}
            title={"HIPAA Authorization"}
            agreementKey={"hipaaAuthorization"}
          />
        </Route>
        {/* Survey Pages */}
        <Route exact path={PATHS.GETTING_STARTED_SURVEY}>
          <GettingStartedSurveyPage {...props} />
        </Route>
        <Route exact path={PATHS.CANCER_SURVEY}>
          <CancerSurveyPage {...props} />
        </Route>
        <Route exact path={PATHS.ORDER_KIT_INFORMED_CONSENT_SURVEY}>
          <InformedConsentSurveyPage {...props} />
        </Route>
        {/* Kit Ordering */}
        <Route exact path={PATHS.ORDER_KIT}>
          <OrderingPage
            goBackPath={PATHS.LANDING_PAGE}
            goBackTitle={"Dashboard"}
            {...props}
          />
        </Route>
        <Route exact path={PATHS.ORDER_KIT_SELECTION}>
          <TestKitSelectionPage
            goBackPath={PATHS.LANDING_PAGE}
            goBackTitle={"Dashboard"}
            {...props}
          />
        </Route>
        <Route exact path={PATHS.ORDER_KIT_CONFIRMATION}>
          <OrderKitConclusionPage {...props} />
        </Route>
        {/* Report and Results */}
        <Route path={`${PATHS.TEST_RESULTS_PAGE}/:glOrderId/:documentId`}>
          <TestResultDocumentPage {...props} />
        </Route>
        <Route path={`${PATHS.TEST_RESULTS_PAGE}/:glOrderId`}>
          <TestResultsPage {...props} />
        </Route>
        <Route exact path={PATHS.REPORT_SHARING_SURVEY}>
          <ReportingSharingPage {...props} />
        </Route>
        <Route exact path={PATHS.REPORT_SHARING_CONCLUSION}>
          <TransmissionConclusionPage {...props} />
        </Route>
        {/* CMS Controlled Pages */}
        <Route exact path={PATHS.HELP_CENTER}>
          <HelpCenterPage {...props} />
        </Route>
        {/* Redirects to CMS for legacy links */}
        <Route exact path={PATHS.RESOURCE_CENTER}>
          <Redirect to={"/content/help-center/"} />;
        </Route>
        <Route exact path={PATHS.CANCER_RESOURCE_LIBRARY}>
          <Redirect to={"/content/help-center/"} />;
        </Route>
        <Route exact path={PATHS.PROGRAM_INFORMATION}>
          <Redirect
            to={"/content/help-center/articles/genomic-life-program-overview/"}
          />
        </Route>
        <Route exact path={PATHS.CANCER_AND_GENOMICS}>
          <Redirect to={"/content/help-center/topics/health-library/"} />
        </Route>
        <Route exact path={PATHS.WHY_GENOMICS}>
          <Redirect to={"/content/help-center/"} />;
        </Route>
        <Route exact path={PATHS.CANCER_GUARDIAN_RESOURCE}>
          <Redirect
            to={
              "/content/help-center/topics/benefits-services/cancer-navigation/"
            }
          />
          ;
        </Route>
        {/* Utility Pages */}
        <Route exact path={PATHS.MAINTENANCE}>
          <MaintenancePage exact path={PATHS.MAINTENANCE} />
        </Route>
      </Switch>
      <IdleTimer />
    </BrowserRouter>
  );
}

export default AppRouter;
