import Translate from "../../../../../translate/Translate";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import CenteredContent from "../../../../sharedComponents/CenteredContent";
import Qcard from "../../../../sharedComponents/Qcard";
import LookingForMoreStyles from "./LookingForMore.styles";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../../../constants/pathConstants";
//import icons
import BillingInformation from "../../../../../assets/images/billing.svg";
import ResourceCenter from "../../../../../assets/images/resource_center.svg";
import CancerDiagnosis from "../../../../../assets/images/cancer_diagnosis_support.svg";

const LookingForMore = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("md"));
  const classes = LookingForMoreStyles();
  const history = useHistory();

  const cards = [
    {
      text: "Cancer Diagnosis & Support",
      icon: CancerDiagnosis,
      route: PATHS.CANCER_GUARDIAN_RESOURCE
    },
    {
      text: "Resource Center",
      icon: ResourceCenter,
      route: PATHS.RESOURCE_CENTER
    },
    {
      text: "Billing Information",
      icon: BillingInformation,
      route: PATHS.ACCOUNT_MANAGEMENT_PAYMENT_INFORMATION
    }
  ];

  return (
    <Fragment>
      <Grid
        container
        className={classes.mainContainer}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <CenteredContent>
            <Typography
              variant="deprecated_h3"
              className={classes.gotQuestionTitle}
              id="gotQuestions"
            >
              <Translate text="Looking for more?" />
            </Typography>
          </CenteredContent>
        </Grid>
        <CenteredContent>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              className={classes.cardsContainer}
              justifyContent={!smDown && "center"}
              alignItems="center"
            >
              {cards.map((card, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={3}
                  className={classes.questionCards}
                  id={card.text.toLowerCase().replace(/\s/g, "-")}
                  onClick={() => history.push(card.route)}
                >
                  <CenteredContent>
                    <Qcard title={card.text} icon={card.icon} />
                  </CenteredContent>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </CenteredContent>
      </Grid>
    </Fragment>
  );
};

export default LookingForMore;
