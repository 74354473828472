import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";
import { themeTypography } from "../../../../../theme/themeTypography";
import checkIcon from "../../../../../assets/icons/checkIcon.svg";

// Some of these could be moved to styles for their respective
//  question types if this gets too big in here
const SurveyWrapperStyles = makeStyles((theme) => ({
  loadingContainer: {
    height: "50vh"
  },
  surveyContainer: {
    flex: 1,
    "--background-dim": "#FFF",
    "--primary": "#000",
    "& .sd-progress": {
      margin: "0 16px 5px 16px"
    },
    "& .sd-progress__text": {
      display: "none"
    },
    "& .sd-progress__bar": {
      backgroundColor: COLOR_CONSTANTS.COLOR_SUCCESS
    },
    "& .sd-container-modern": {
      padding: "0px 20px"
    },
    "& .sd-action-bar": {
      paddingTop: "5px"
    },
    "& .sd-page": {
      padding: 0
    },
    "& .sd-description": {
      marginTop: "10px"
    },
    "& .sd-root-modern": {
      "& .sd-body": {
        display: "flex",
        flexFlow: "column"
      },
      "& .sd-page": {
        paddingBottom: "40px"
        //use this if we decide to stick it to the bottom
        //height: "calc(100vh - 250px)"
      },
      "& .sd-title": {
        ...themeTypography.deprecated_surveyHeavy
      },
      "& .sd-title.sd-page__title": {
        fontSize: "24px",
        lineHeight: "30px",
        marginTop: "40px"
      },
      "& .sd-description": {
        ...themeTypography.deprecated_surveyNormal
      },
      "& .sd-navigation__next-btn, .sd-navigation__prev-btn, .sd-navigation__complete-btn": {
        border: "1px solid rgba(0,0,0,0)",
        boxShadow: "none",
        "&:focus": {
          border: "1px solid",
          boxShadow: "none",
          borderColor: COLOR_CONSTANTS.COLOR_SUCCESS
        }
      },
      "& #sv-nav-next, #sv-nav-complete": {
        marginLeft: "auto"
      },
      "& .sd-navigation__complete-btn": {
        border: "1px solid rgba(0,0,0,0)",
        boxShadow: "none",
        borderRadius: "100px",
        fontweight: "800",
        textTransform: "uppercase",
        backgroundColor: COLOR_CONSTANTS.COLOR_INFO,
        "&:focus": {
          color: "#FFF",
          border: "1px solid",
          boxShadow: "none",
          borderColor: COLOR_CONSTANTS.COLOR_SUCCESS
        },
        "&:hover": {
          color: "#FFF",
          backgroundColor: COLOR_CONSTANTS.COLOR_INFO_10
        },
        "&:disabled": {
          color: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY,
          backgroundColor: "#FFFFFF",
          border: "1px solid",
          boxShadow: "none",
          borderColor: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY
        }
      }
    },
    backgroundColor: "#FFF",
    "& .sd-body": {
      backgroundColor: "#FFF",
      "& .sd-element": { boxShadow: "none", border: "none" },
      "& .sd-boolean__control:focus~.sd-boolean__switch": {
        border: "1px solid",
        boxShadow: "none",
        borderColor: COLOR_CONSTANTS.COLOR_SUCCESS
      },
      "& .sd-boolean": {
        background: "none",
        boxShadow: "none",
        border: "1px solid",
        borderColor: COLOR_CONSTANTS.COLOR_GRAY_40,

        "&:not(.sd-boolean--indeterminate)": {
          borderColor: COLOR_CONSTANTS.COLOR_SUCCESS
        },
        "& .sd-boolean__thumb": {
          border: "1px solid",
          borderColor: COLOR_CONSTANTS.COLOR_SUCCESS,
          backgroundColor: COLOR_CONSTANTS.COLOR_SUCCESS_LIGHT
        }
      },
      "& .sd-input": {
        border: "1px solid",
        borderColor: COLOR_CONSTANTS.COLOR_GRAY_30,
        borderRadius: "5px",
        boxShadow: "none",
        backgroundColor: "white",
        "&:focus": {
          border: "1px solid",
          borderColor: COLOR_CONSTANTS.COLOR_INFO,
          boxShadow: "none"
        }
      },
      "& .sd-radio": {
        padding: "0px",
        borderRadius: "10px",
        width: "auto",
        maxWidth: "335px",
        marginTop: "15px",
        "--primary": "rgba(0,0,0,0)",

        "& label": {
          padding: "20px",
          cursor: "pointer"
        },
        "&.sd-item, .sd-item__decorator": {
          boxShadow: "none",
          border: "1px solid rgba(0,0,0,.15)"
        },
        "&.sd-radio--checked": {
          border: "1px solid",

          borderColor: COLOR_CONSTANTS.COLOR_SUCCESS,
          backgroundColor: COLOR_CONSTANTS.COLOR_SUCCESS_LIGHT
        },
        "& .sd-item__control:focus + .sd-item__decorator": {
          background: "rgba(0,0,0,0)",
          border: "1px solid",
          borderColor: COLOR_CONSTANTS.COLOR_SUCCESS
        },
        "&.sd-radio--checked .sd-item__decorator.sd-radio__decorator": {
          backgroundImage: `url(${checkIcon})`,
          background: COLOR_CONSTANTS.COLOR_SUCCESS,
          "--primary-foreground": "rgba(0,0,0,0)",
          backgroundPosition: "-1px -1px"
        }
      },
      "& .sd-paneldynamic": {
        "& button:not(.sd-action--negative)": {
          color: COLOR_CONSTANTS.COLOR_SUCCESS
        }
      },
      "& [data-key='ghs-invitae-informed-consent-doc0'], [data-key='pgx-oneome-informed-consent-doc0'], [data-key='carrier-myriad-informed-consent-doc0'], [data-key='grp-invitae-informed-consent-doc0']": {
        height: "400px",
        overflowY: "scroll",
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "20px",
        "& .button": {
          backgroundColor: "#336AB3",
          color: "white",
          textDecoration: "none",
          padding: "10px 20px",
          borderRadius: "4px"
        }
      }
    }
  }
}));

export default SurveyWrapperStyles;
