import React from "react";
import { useState } from "react";
import MenuIcon from "../../../../assets/images/MenuIcon.svg";
import RightDrawerStyles from "./RightDrawer.styles";
//Drawer imports
import { Box, Button, Drawer as MUIDrawer, Grid } from "@mui/material";
import { List, ListItem, ListItemText } from "@mui/material";
import { Close } from "@mui/icons-material";
import CenteredContent from "../../../sharedComponents/CenteredContent";
//Import icons
import AccountIcon from "../../../../assets/images/AccountIcon.svg";
import DashboardIcon from "../../../../assets/images/DashboardIcon.svg";
import ProgramsIcon from "../../../../assets/images/ProgramsIcon.svg";
import HealthResourcesIcon from "../../../../assets/images/HealthResourcesIcon.svg";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../../constants/pathConstants";
import { openWebBrowserPage } from "../../../../utils/BrowserUtil";
import Translate from "../../../../translate/Translate";
import { useLogout } from "../../../../hooks/useLogout";

function RightDrawer() {
  const logoutFunction = useLogout();
  const classes = RightDrawerStyles();
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const history = useHistory();

  const OpenRoute = (route) => {
    if (route === null) return;
    history.push(route);
  };

  const LinkItems = [
    {
      id: "dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      route: PATHS.LANDING_PAGE
    },
    {
      id: "account",
      name: "Account",
      icon: AccountIcon,
      route: PATHS.ACCOUNT_MANAGEMENT
    },
    {
      id: "cancerResourceCenterHeader",
      name: "Support & Resources",
      icon: HealthResourcesIcon,
      route: "/content/cancer-resource-library"
    }
  ];

  return (
    <div>
      <img
        src={MenuIcon}
        alt="Menu icon"
        id="menuIcon"
        className={classes.MenuHamburguerIcon}
        onClick={toggleDrawer}
      />
      <MUIDrawer
        open={open}
        anchor="right"
        variant="temporary"
        onClose={toggleDrawer}
        classes={{ root: classes.rootClass, paper: classes.paper }}
      >
        <List>
          <Box
            className={classes.iconXContainer}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Close className={classes.CloseIcon} onClick={toggleDrawer} />
          </Box>
          {LinkItems.map((linkItem, index) => (
            <Box
              key={`${index}-${linkItem.name}`}
              onClick={toggleDrawer}
              onKeyDown={toggleDrawer}
            >
              <ListItem
                onClick={() => {
                  linkItem.webPage
                    ? openWebBrowserPage(linkItem.route)
                    : OpenRoute(linkItem.route);
                }}
                selected={false}
                button
                key={linkItem.id}
                id={`${linkItem.id}`}
              >
                <Box className={classes.drawerWidth}>
                  <Grid container className={classes.optionContent}>
                    <Grid item className={classes.iconContent}>
                      <CenteredContent className={classes.LinkIconContainer}>
                        <img
                          src={linkItem.icon}
                          alt={linkItem.name}
                          className={classes.ImageSize}
                          name={linkItem.name}
                        />
                      </CenteredContent>
                    </Grid>
                    <Grid item className={classes.linkTextContent}>
                      <ListItemText
                        disableTypography={true}
                        primary={<Translate text={linkItem.name} />}
                        className={classes.LinkTextItem}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
            </Box>
          ))}
          <CenteredContent>
            <Button
              variant="contained"
              className={classes.LogoutButton}
              onClick={logoutFunction}
              id="logOut"
            >
              {" "}
              <Translate text="Log out" />{" "}
            </Button>
          </CenteredContent>
        </List>
      </MUIDrawer>
    </div>
  );
}

export default RightDrawer;
