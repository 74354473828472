import { Box, Checkbox, Typography } from "@mui/material";
import GLSelect from "../formComponents/GLSelect";
import GLTextField from "../formComponents/GLTextField";
import {
  FormFieldContents,
  pickSubsetOfAttributes,
  useFormFieldState
} from "../formComponents/FormUtils";
import GLZipField from "../formComponents/GLZipField";
import GLStateSelect from "../formComponents/GLStateSelect";
import GLEmailField from "../formComponents/GLEmailField";
import GLPhoneField from "../formComponents/GLPhoneField";
import { useState } from "react";
import { motion } from "framer-motion";
import GLButton from "components/v3/sharedComponents/GLButton";
import CheckBoxAltIcon from "../../../../assets/icons/checkBoxAlt.svg";
import { COLORS } from "components/v3/Theme/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserPersonalInformation,
  updateFieldsInUserPersonalInformation
} from "store/reducers/user/UserAccountSlice";
import { getMemberProfile, patchMemberProfile } from "apis/v3/memberApi";
import { openGLSnackbar } from "store/reducers/snackbar/openGLSnackbarSlice";
import {
  GENDERS,
  PREFERRED_CONTACT_METHODS,
  PREFERRED_CONTACT_TIMES,
  PRONOUNS,
  SEXES
} from "constants/generalConstants";

export interface ProfileFormFields {
  [key: string]: FormFieldContents;
  preferredName?: string;
  sexAssignedAtBirth?: string;
  gender?: string;
  pronouns?: string;
  email?: string;
  phone?: string;
  preferredContactMechanism?: "phone" | "email" | "either";
  preferredContactTime?: "morning" | "afternoon" | "either";
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}

interface ProfileFormProps {
  onFormFieldsUpdated: (formFields: ProfileFormFields) => void;
  onSubmitted: () => void;
}

const ProfileForm: React.FC<ProfileFormProps> = ({
  onFormFieldsUpdated,
  onSubmitted
}) => {
  const dispatcher = useDispatch();
  const memberInformation = useSelector(selectUserPersonalInformation);
  const dataToPrePopulateForm: ProfileFormFields = pickSubsetOfAttributes(
    memberInformation,
    [
      "preferredName",
      "sexAssignedAtBirth",
      "gender",
      "pronouns",
      "email",
      "phone",
      "preferredContactMechanism",
      "preferredContactTime",
      "addressLine1",
      "addressLine2",
      "city",
      "state",
      "postalCode"
    ]
  );

  const { formFields, formStatus, handleInputChange } = useFormFieldState<
    ProfileFormFields
  >(dataToPrePopulateForm, onFormFieldsUpdated);
  const [
    accuracyAttestationIsChecked,
    setAccuracyAttestationIsChecked
  ] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);

  const nameTooltip = (
    <span>
      <p>
        Your legal name needs to be provided through your employer. This
        connects you to your benefits and follows HIPAA guidelines.
      </p>
      <p>
        <strong>Not you?</strong>
        <br />
        If this is incorrect, please{" "}
        <a
          href="mailto:navigation@genomiclife.com"
          style={{ color: COLORS.WHITE }}
        >
          contact us
        </a>{" "}
        and we will help you correct it.
      </p>
    </span>
  );

  const dobTooltip = (
    <span>
      <p>Your Date of Birth was provided to Genomic Life by your employer.</p>
      <p>
        {" "}
        If your Date of Birth is not accurate, please contact your HR Benefits
        Office to correct it.
      </p>
    </span>
  );

  const formIsErrorFree = formStatus.invalidFields.length === 0;
  const formCanBeSubmitted = formIsErrorFree && accuracyAttestationIsChecked;

  const handleSubmit = (): void => {
    setButtonSpinning(true);
    patchMemberProfile(formFields)
      .then((response) => {
        if (response.status === 200) {
          dispatcher(
            openGLSnackbar({
              variant: "success",
              header: "Success",
              subText: "Member Information was saved successfully!"
            })
          );
          getMemberProfile()
            .then(({ data }) => {
              dispatcher(updateFieldsInUserPersonalInformation(data));
            })
            .then(() => {
              setButtonSpinning(false);
              onSubmitted();
            });
          return true;
        } else {
          dispatcher(
            openGLSnackbar({
              variant: "error",
              header: "Error",
              subText:
                "Sorry, something went wrong. Please try again and reach out to us if you keep having trouble."
            })
          );
          setButtonSpinning(false);
        }
      })
      .catch((error) => {
        dispatcher(
          openGLSnackbar({
            variant: "error",
            header: "Error",
            subText:
              "Sorry, something went wrong. Please try again and reach out to us if you keep having trouble."
          })
        );
        setButtonSpinning(false);
        return false;
      });
  };

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: COLORS.WHITE,
        borderRadius: 8,
        gap: "30px",
        marginTop: {
          xs: "50px",
          md: "0"
        },
        padding: "30px"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px"
        }}
      >
        <Typography
          mb={1}
          sx={{ typography: { xs: "h5_black", md: "h4_black" } }}
        >
          Personalize Your Profile
        </Typography>
        <Typography variant="body_sm_dark_gray">
          Having your accurate personal details helps us provide you with timely
          and relevant information, support, and access to services.
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "1px",
            backgroundColor: COLORS.ACCENT3,
            marginTop: {
              xs: "15px",
              md: "30px"
            }
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3
        }}
      >
        <Typography variant="body_strong_lg_primary">Member Details</Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row"
              },
              gap: "16px"
            }}
          >
            <GLTextField
              disabled
              required
              id="userName"
              label="Your Name"
              value={`${memberInformation.firstName} ${memberInformation.lastName}`}
              tooltip={nameTooltip}
            />
            <GLTextField
              disabled
              required
              id="userDOB"
              label="Date of Birth"
              value={memberInformation.dob}
              tooltip={dobTooltip}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px"
            }}
          >
            <GLTextField
              id="preferredName"
              onChange={handleInputChange}
              value={formFields.preferredName}
              label="Would you prefer to be called a different name?"
              maxLength={13}
            />
            <Typography variant="body_xs_dark_gray">
              This is the name we will reference you by throughout your journey
              with Genomic Life. You can change this at any time.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: {
              xs: "column",
              md: "row"
            },
            "& > *": {
              flexGrow: 1,
              minWidth: 0
            }
          }}
        >
          <GLSelect
            required
            id="sexAssignedAtBirth"
            onChange={handleInputChange}
            label="Sex assigned at Birth"
            options={SEXES}
            value={formFields.sexAssignedAtBirth}
            defaultValue=""
            tooltip="Some health conditions affect males and females differently. Providing this information allows us to better tailor your personalized health guidance and action plans."
          />
          <GLSelect
            id="gender"
            onChange={handleInputChange}
            label="Gender Identity"
            options={GENDERS}
            value={formFields.gender}
            defaultValue=""
          />
          <GLSelect
            id="pronouns"
            onChange={handleInputChange}
            label="Pronouns"
            options={PRONOUNS}
            value={formFields.pronouns}
            defaultValue=""
          />
        </Box>
        <Typography variant="body_xs_dark_gray" mt={-2}>
          So we can provide age and sex appropriate screening-related
          information throughout your experience with Genomic Life.
        </Typography>
        <Typography variant="body_strong_lg_primary" mt="8px">
          How would you like us to contact you?
        </Typography>
        <Typography variant="body_sm_dark_gray" mt={-2}>
          We'll use this data to reach you with updates and important
          information.
        </Typography>

        <GLEmailField
          required
          label="Contact Email Address"
          id="email"
          onChange={handleInputChange}
          value={formFields.email}
        />
        <Typography variant="body_xs_dark_gray" mt={-2}>
          This is where we will send updates and information. The email you use
          to access Genomic Life's portal won't be changed.
        </Typography>

        <GLPhoneField
          required
          label="Contact Phone Number"
          id="phone"
          onChange={handleInputChange}
          value={formFields.phone}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row"
            },
            gap: "20px",
            "& > *": {
              flexGrow: 1,
              minWidth: 0
            }
          }}
        >
          <GLSelect
            required
            id="preferredContactMechanism"
            onChange={handleInputChange}
            label="I prefer"
            options={PREFERRED_CONTACT_METHODS}
            value={formFields.preferredContactMechanism}
            defaultValue=""
          />
          <GLSelect
            required
            id="preferredContactTime"
            onChange={handleInputChange}
            label="Best time to contact"
            options={PREFERRED_CONTACT_TIMES}
            value={formFields.preferredContactTime}
            defaultValue=""
          />
        </Box>
        <Typography variant="body_xs_dark_gray" mt={-2}>
          Genomic Life may reach out to you by email, phone or text with
          information that is important to your health.
        </Typography>

        <Typography variant="h6_primary" fontWeight="bold" mt={2}>
          Mailing Address
        </Typography>
        <Typography variant="body_sm_dark_gray" mt={-2} mb={2}>
          Please provide your preferred mailing address for accurate and timely
          deliveries as well as the best customer service and support.{" "}
          <strong>We accept US addresses only, no P.O. boxes.</strong>
        </Typography>

        <GLTextField
          required
          label="Street Address"
          id="addressLine1"
          onChange={handleInputChange}
          value={formFields.addressLine1}
        />
        <GLTextField
          label="Address Line 2"
          id="addressLine2"
          onChange={handleInputChange}
          value={formFields.addressLine2}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row"
            },
            gap: "20px",
            "& > *": {
              flexGrow: 1,
              minWidth: 0
            }
          }}
        >
          <GLTextField
            required
            label="City"
            id="city"
            onChange={handleInputChange}
            value={formFields.city}
          />
          <GLStateSelect
            required
            label="State"
            id="state"
            onChange={handleInputChange}
            value={formFields.state}
          />
          <GLZipField
            required
            label="Zip Code"
            id="postalCode"
            onChange={handleInputChange}
            value={formFields.postalCode}
          />
        </Box>

        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={
            formIsErrorFree
              ? { height: "auto", opacity: 1 }
              : { height: 0, opacity: 0 }
          }
          transition={{ duration: 0.5 }}
          style={{ overflow: "hidden" }}
        >
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={accuracyAttestationIsChecked}
              onChange={() =>
                setAccuracyAttestationIsChecked(!accuracyAttestationIsChecked)
              }
              disabled={false}
            />
            <Typography variant="body_strong_black">
              I confirm that the information provided above is accurate to the
              best of my knowledge.
            </Typography>
          </Box>
        </motion.div>
      </Box>

      <GLButton
        text="Save profile details"
        onClick={handleSubmit}
        buttonType="primary"
        loading={buttonSpinning}
        disabled={!formCanBeSubmitted}
        leftIcon={<img src={CheckBoxAltIcon} alt="check Icon" />}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3
        }}
      >
        <Typography variant="body_sm_dark_gray">
          It is important to keep your contact information correct and
          up-to-date so that we can provide you with timely and relevant
          information, support, and resources to help you maintain and improve
          your health.
        </Typography>
        <Typography variant="body_sm_dark_gray">
          No email address or mobile information will be shared with third
          parties/affiliates for marketing/promotional purposes. Visit our
          Privacy Policy page for more information on how your data is managed.
        </Typography>
        <Typography variant="body_sm_dark_gray">
          Additionally, maintaining current information helps us adhere to HIPAA
          guidelines, which are designed to protect your privacy and the
          confidentiality of your health information. Your security and privacy
          are our top priorities, and keeping your information up-to-date is a
          key part of this commitment.
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileForm;
