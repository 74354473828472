// Fill this in
export const kitsGLHasOfferedInThePast = { uuids: [], names: ["PGx"] };

// This can all be cleaned up now that we have test_type on the kits
export const figureOutWhatKitsGLOffersThatThisMemberHasntOrdered = (
  kitsGLCurrentlyOffers,
  kitsAlreadyOrdered
) => {
  return kitsGLCurrentlyOffers.filter((kit) => {
    return !kitsAlreadyOrdered.find((ordered) => {
      return (
        ordered.description.uuid === kit.test_uuid ||
        ordered.description.name === kit.name ||
        ordered.glTestType === kit.test_type ||
        (ordered.description.name === "PGx" &&
          kit.name === "Pharmacogenomics Panel") ||
        (ordered.description.name === "Carrier" &&
          kit.name === "Comprehensive Carrier Screen")
      );
    });
  });
};

/*
  "<host>/orderKit/info?dzqDB37ztZUE" - GHS
  "<host>/orderKit/info?RVvrWqsPvK3U" - GHS & PGx
  "<host>/orderKit/info?Uvg9wDZtEkcZ" - GHS & Carrier
  "<host>/orderKit/info?BhaLg9NLZsfk" - GHS & PGx & Carrier
  "<host>/orderKit/info?mALQkApUBY5s" - PGx
  "<host>/orderKit/info?x8pqSgd7CHa2" - PGx & Carrier
  "<host>/orderKit/info?DAr8jmG7yv88" - Carrier
*/

export const figureOutKitsToOfferMemberRightNow = (
  kitsGLCurrentlyOffers,
  kitsAlreadyOrdered,
  relevantSurveyAnswers,
  kitSuggestionOverrideToken,
  glProgramId
) => {
  const kitsGLOffersThatThisMemberHasntOrdered = figureOutWhatKitsGLOffersThatThisMemberHasntOrdered(
    kitsGLCurrentlyOffers,
    kitsAlreadyOrdered
  );
  switch (kitSuggestionOverrideToken) {
    case "?dzqDB37ztZUE":
      return kitsGLCurrentlyOffers.reduce((result, kit) => {
        if (kit.name === "Genetic Health Screen - Comprehensive") {
          result.push(kit);
        }
        return result;
      }, []);
    case "?RVvrWqsPvK3U":
      return kitsGLCurrentlyOffers.reduce((result, kit) => {
        if (
          [
            "Genetic Health Screen - Comprehensive",
            "Pharmacogenomics Panel",
            "PGx"
          ].includes(kit.name)
        ) {
          result.push(kit);
        }
        return result;
      }, []);
    case "?Uvg9wDZtEkcZ":
      return kitsGLCurrentlyOffers.reduce((result, kit) => {
        if (
          kit.name === "Genetic Health Screen - Comprehensive" ||
          kit.name === "Comprehensive Carrier Screen"
        ) {
          result.push(kit);
        }
        return result;
      }, []);
    case "?BhaLg9NLZsfk":
      return kitsGLCurrentlyOffers.reduce((result, kit) => {
        if (
          [
            "Genetic Health Screen - Comprehensive",
            "Comprehensive Carrier Screen",
            "Pharmacogenomics Panel",
            "PGx"
          ].includes(kit.name)
        ) {
          result.push(kit);
        }
        return result;
      }, []);
    case "?mALQkApUBY5s":
      return kitsGLCurrentlyOffers.reduce((result, kit) => {
        if (["Pharmacogenomics Panel", "PGx"].includes(kit.name)) {
          result.push(kit);
        }
        return result;
      }, []);
    case "?x8pqSgd7CHa2":
      return kitsGLCurrentlyOffers.reduce((result, kit) => {
        if (
          [
            "Comprehensive Carrier Screen",
            "Pharmacogenomics Panel",
            "PGx"
          ].includes(kit.name)
        ) {
          result.push(kit);
        }
        return result;
      }, []);
    case "?DAr8jmG7yv88":
      return kitsGLCurrentlyOffers.reduce((result, kit) => {
        if (kit.name === "Comprehensive Carrier Screen") {
          result.push(kit);
        }
        return result;
      }, []);
    default:
      return kitsGLOffersThatThisMemberHasntOrdered.reduce((result, kit) => {
        if (
          kit.name === "Genetic Health Screen - Comprehensive" ||
          (kit.name === "Comprehensive Carrier Screen" &&
            relevantSurveyAnswers["planning-pregnancy-within-a-year"] ===
              true &&
            glProgramId !== "Genetic Screen") ||
          (["Pharmacogenomics Panel", "PGx"].includes(kit.name) &&
            glProgramId !== "Genetic Screen" &&
            (relevantSurveyAnswers["taking-5-plus-medications"] === true ||
              relevantSurveyAnswers["taking-following-types-medication"] ===
                true))
        ) {
          result.push(kit);
        }
        return result;
      }, []);
  }
};
