import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import TestKitSelectionPageStyles from "./TestKitSelectionPage.styles";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import GeneticHealtIcon from "../../../../assets/icons/GeneticHealthScreenIconography.svg";
import CarrierIcon from "../../../../assets/icons/CarrierIconography.svg";
import PgxIcon from "../../../../assets/icons/PGxIconography.svg";
//Constants
import { PATHS } from "../../../../constants/pathConstants";
//Assets
import ProductCard from "../../../sharedComponents/ProductCard";
import Layout from "../../../layout/Layout";
import PageTitle from "../../../../utils/PageTitle";
import TestKitSelectionFooterBar from "./TestKitSelectionFooterBar";
import Translate from "../../../../translate/Translate";
import {
  selectTestKitsGLIsCurrentlyOffering,
  selectExistingOrderS3ResultFileContainers,
  selectExistingOrders
} from "../../../../store/reducers/order/OrderInformationSlice";
import { selectAllPreviousSurveyAnswers } from "../../../../store/reducers/survey/SurveySlice";
import { selectSubsetOfAnswersFromSurveyAnswers } from "../../Surveys/Utilities/util";

import { downloadFile } from "../../../../utils/utils";
//Hooks
import { useSelector } from "react-redux";

import { downloadOrderDocuments } from "../../../../apis/ordersApi";
import { figureOutKitsToOfferMemberRightNow } from "../../../../utils/testKitUtils";
import { useLocation, useHistory } from "react-router-dom";
import { selectUserPersonalInformation } from "store/reducers/user/UserAccountSlice";
const GHS_UUID = process.env.REACT_APP_GHS_TEST_UUID;

const pushDescriptionIntoKitObject = (kitArray) => {
  const kitArrayDescription = {
    ghs_test_uuid: {
      description:
        "Understand your health with this accurate, medical-grade DNA test that identifies a predisposition to developing hereditary cancers, cardiovascular diseases, and other significant conditions."
    },
    "3cf65de6-41f9-4e98-a256-231c871e3377": {
      // Legacy test kit
      description:
        "Understand your health with this accurate, medical-grade DNA test that identifies a predisposition to developing hereditary cancers, cardiovascular diseases, and other significant conditions."
    },
    carrier_test_uuid: {
      description:
        "Receive answers and inform family planning with carrier screening that examines your genetics to identify a potential risk of having a child affected by a genetic condition.",
      chipInformation: "Planning on having a child?"
    },
    "71957dc2-ee1e-4d9d-bc72-507aeb0ff992": {
      // Legacy test kit
      description:
        "Learn what drugs work for you with a PGx test that uncovers how you metabolize and respond to medications. This knowledge helps reduce adverse drug reactions.",
      chipInformation: "Taking medications?"
    },
    "8845bdbe-330e-4afc-82ed-f1cfa1d808ad": {
      // Legacy test kit
      description:
        "Learn what drugs work for you with a PGx test that uncovers how you metabolize and respond to medications. This knowledge helps reduce adverse drug reactions.",
      chipInformation: "Taking medications?"
    },
    pgx_test_uuid: {
      // OneOme test kit - current
      description:
        "Learn what drugs work for you with a PGx test that uncovers how you metabolize and respond to medications. This knowledge helps reduce adverse drug reactions.",
      chipInformation: "Taking medications?"
    },
    "429fc77b-c1c1-455e-937c-de67db27e058": {
      // Stage OneOme kit
      description:
        "Learn what drugs work for you with a PGx test that uncovers how you metabolize and respond to medications. This knowledge helps reduce adverse drug reactions.",
      chipInformation: "Taking medications?"
    }
  };

  return kitArray.map((kit) => {
    return { ...kit, ...kitArrayDescription[kit.test_uuid] };
  });
};

const TestKitSelectionPage = (props) => {
  const windowSize = useWindowSize();
  const PrimaryUsableHeight = windowSize.height;
  const classes = TestKitSelectionPageStyles();
  const fileIds = useSelector(selectExistingOrderS3ResultFileContainers);
  const kitsGLCurrentlyOffers = pushDescriptionIntoKitObject(
    useSelector(selectTestKitsGLIsCurrentlyOffering)
  );
  const existingTestKitOrders = useSelector(selectExistingOrders);
  const history = useHistory();
  const previousSurveyAnswers = useSelector(selectAllPreviousSurveyAnswers);
  const userInformation = useSelector(selectUserPersonalInformation);
  const kitSuggestionOverrideToken = useLocation().search;

  // This will be used to prevent members from ordering kits using
  //  override links from clinical unless they have completed the survey
  //  answers we need to actually order. Need to figure out what these are
  //  before splitting up the getting started survey
  const QUESTIONS_REQUIRED_BY_LABS_TO_ORDER = [
    "required-question-name-goes-here"
  ];

  const QUESTIONS_REQUIRED_TO_DECIDE_WHAT_KITS_TO_OFFER = [
    "active-cancer-diagnosis",
    "dna-transplant",
    "taking-medication-want-to-learn",
    "taking-following-types-medication",
    "taking-5-plus-medications",
    "taking-medication",
    "planning-pregnancy-within-a-year"
  ];

  const relevantSurveyAnswers = selectSubsetOfAnswersFromSurveyAnswers(
    previousSurveyAnswers,
    QUESTIONS_REQUIRED_TO_DECIDE_WHAT_KITS_TO_OFFER
  );

  if (
    Object.keys(relevantSurveyAnswers).length <
    QUESTIONS_REQUIRED_BY_LABS_TO_ORDER.length
  ) {
    history.push(PATHS.LANDING_PAGE);
  }

  const kitsToOfferMemberRightNow = figureOutKitsToOfferMemberRightNow(
    kitsGLCurrentlyOffers,
    existingTestKitOrders,
    relevantSurveyAnswers,
    kitSuggestionOverrideToken,
    userInformation.glProgramId
  );

  const [
    uuidsOfKitsSelectedToBeOrdered,
    setUuidsOfKitsSelectedToBeOrdered
  ] = useState(
    kitsToOfferMemberRightNow.map((kit) => {
      return kit.test_uuid;
    })
  );

  const getKitFiles = (testUuid) => {
    if (testUuid) {
      const exactId = fileIds.filter((item) => item.uuid === testUuid);
      return exactId && exactId[0] ? exactId[0].fileIDs : [];
    }
    return [];
  };

  function downloadFiles(uuid) {
    let currentOrder = existingTestKitOrders.filter(
      (orders) => orders.uuid === uuid
    );
    let orderId = currentOrder[0].glOrderId;
    let filesPdfIds = getKitFiles(uuid);
    if (filesPdfIds && filesPdfIds.length > 0) {
      filesPdfIds.map((fileId) => {
        return downloadOrderDocuments(orderId, fileId)
          .then((response) => {
            downloadFile(fileId, response);
          })
          .catch((error) => {
            return error;
          });
      });
    }
  }

  const renderCardIcon = (testName) => {
    if (testName === "Genetic Health Screen") {
      return GeneticHealtIcon;
    }
    if (testName === "Carrier Screening") {
      return CarrierIcon;
    }
    return PgxIcon;
  };

  const addTest = (uuid) => {
    if (uuidsOfKitsSelectedToBeOrdered.indexOf(uuid) === -1) {
      setUuidsOfKitsSelectedToBeOrdered([
        ...uuidsOfKitsSelectedToBeOrdered,
        uuid
      ]);
    }
  };

  const removeTest = (uuid) => {
    const filteredKitUuidList = Object.assign(
      [],
      uuidsOfKitsSelectedToBeOrdered.filter((id) => {
        return id !== uuid;
      })
    );
    setUuidsOfKitsSelectedToBeOrdered(filteredKitUuidList);
  };

  function isSelected(uuid) {
    return uuidsOfKitsSelectedToBeOrdered.includes(uuid);
  }

  const selectOrDeselectKit = (uuid) => {
    if (isSelected(uuid)) {
      removeTest(uuid);
    } else {
      addTest(uuid);
    }
  };

  const getKitDetails = (kit) => {
    if (existingTestKitOrders) {
      return existingTestKitOrders.find((testItem) => {
        return (
          testItem.description.uuid === kit.test_uuid ||
          testItem.description.name === kit.name
        );
      });
    }
    return null;
  };

  const renderFooterBar = () => {
    return (
      <Grid
        id="orderKitBar"
        container
        justifyContent="center"
        alignItems="flex-end"
        className={classes.orderKitBar}
      >
        <Grid item xs={12}>
          <TestKitSelectionFooterBar
            selectedKitUuids={uuidsOfKitsSelectedToBeOrdered}
          />
        </Grid>
      </Grid>
    );
  };

  const renderBundleCards = () => {
    return (
      <Grid item xs={12} className={classes.bundleContainer}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="center">
              {kitsGLCurrentlyOffers &&
                kitsGLCurrentlyOffers.length > 0 &&
                kitsGLCurrentlyOffers.map((kit, index) => {
                  if (
                    userInformation.glProgramId === "Genetic Screen" &&
                    kit.test_uuid !== GHS_UUID
                  ) {
                    return null;
                  } else {
                    return (
                      <div
                        className={`${classes.productCard} kit-selection-card`}
                        key={`${index}-${kit.test_uuid.slice(0, 2)}`}
                      >
                        <ProductCard
                          keyId={kit.test_uuid}
                          isCardRecommended={
                            !!kitsToOfferMemberRightNow.find(
                              (suggestedKit) =>
                                suggestedKit.test_uuid === kit.test_uuid
                            )
                          }
                          isCardSelected={isSelected(kit.test_uuid)}
                          onSelect={selectOrDeselectKit}
                          testName={kit.test_name}
                          productIdentifier={kit.test_uuid}
                          buttonAction={PATHS.ORDER_KIT}
                          buttonSecondaryAction={downloadFiles}
                          cardTitle={kit.test_name}
                          altImg={kit.test_name}
                          cardImage={renderCardIcon(kit.test_name)}
                          buttonText="SELECT"
                          kitDetails={getKitDetails(kit)}
                          userHasOrder={existingTestKitOrders.length > 0}
                          cardDescription={kit.description}
                          hasFilesReadyToDownload={
                            getKitFiles(kit.test_uuid).length > 0
                          }
                        />
                      </div>
                    );
                  }
                })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderTopSection = () => {
    return (
      <React.Fragment>
        <Grid
          className={classes.container}
          container
          xs={11}
          sm={10}
          md={10}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="deprecated_body1"
            className={classes.requestScreeningTitle}
          >
            <Translate text="According to your survey answers, you are eligible for these tests." />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {renderBundleCards()}
        </Grid>
      </React.Fragment>
    );
  };

  PageTitle("Request a screening");
  return (
    <div style={{ height: PrimaryUsableHeight }}>
      <Layout
        headerType="integrated"
        headerText={"The genomics that matter to you"}
        goBackPath={PATHS.LANDING_PAGE}
        goBackTitle="Dashboard"
        hasNotHeader={true}
        hasNoFooter={true}
      >
        <Grid
          container
          xs
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {renderTopSection()}
        </Grid>
        {renderFooterBar()}
      </Layout>
    </div>
  );
};

export default TestKitSelectionPage;
