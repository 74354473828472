import { useEffect, useMemo, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { PATHS } from "../../../../constants/pathConstants";
import FormSummary from "../../../sharedComponents/FormSummary/FormSummaryComponent";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { useSelector, useDispatch } from "react-redux";
import {
  selectKitsSelectedToBeOrdered,
  setKitsSelectedToBeOrdered,
  selectTestKitsGLIsCurrentlyOffering,
  setExistingOrders,
  setUuidsOfKitsJustOrderedToBeShownOnConclusionScreen,
  selectExistingOrders
} from "../../../../store/reducers/order/OrderInformationSlice";
import LoadingPage from "../../../sharedComponents/LoadingPage";
import OrderKitRequestConfirmationStyles from "./OrderKitRequestConfirmation.styles";
import Translate from "../../../../translate/Translate";
import { requestOrder } from "../../../../apis/ordersApi";
import { openGLSnackbar } from "../../../../store/reducers/snackbar/openGLSnackbarSlice";
import Mixpanel from "../../../../utils/mixpanel";
import { selectUserPersonalInformation } from "../../../../store/reducers/user/UserAccountSlice";
import { TEST_TYPE_BY_UUID } from "../../../v2/Pages/TestResultsPage/testLanguage";

const OrderKitRequestConfirmation = (props) => {
  const { onBackToMemberInfo, onBackToShippingInfo } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const kitsSelectedToBeOrdered = useSelector(selectKitsSelectedToBeOrdered);
  const previouslyExistingKitOrders = useSelector(selectExistingOrders);
  const testKitsGLIsCurrentlyOffering = useSelector(
    selectTestKitsGLIsCurrentlyOffering
  );
  const [fields, setFields] = useState([]);
  const testKitsGLIsCurrentlyOfferingMap = useMemo(() => {
    return testKitsGLIsCurrentlyOffering.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.test_uuid]: curr
      };
    }, {});
  }, [testKitsGLIsCurrentlyOffering]);

  const memberInformation = useSelector(selectUserPersonalInformation);

  const [isProccesing, setIsProccesing] = useState(false);

  const windowSize = useWindowSize();
  const usableHeight = windowSize.height - 205;
  const buttonPadding = usableHeight * 0.1;
  const classes = OrderKitRequestConfirmationStyles();

  const setSexFullName = (sexValue) => {
    switch (sexValue) {
      case "M":
        return "Male";
      case "F":
        return "Female";
      case "I":
        return "Intersex";
      case "U":
      default:
        return "Unknown";
    }
  };
  const fullName = `${memberInformation.firstName}${
    memberInformation.middleName && memberInformation.middleName.length > 0
      ? ` ${memberInformation.middleName[0]}.`
      : ""
  } ${memberInformation.lastName}`;
  const sexAssignedAtBirth =
    memberInformation.sexAssignedAtBirth &&
    memberInformation.sexAssignedAtBirth.length > 0
      ? `${setSexFullName(
          memberInformation.sexAssignedAtBirth[0].toUpperCase()
        )}`
      : "";
  const gender =
    memberInformation && memberInformation.gender
      ? `${memberInformation.gender[0].toUpperCase()}${memberInformation.gender.substring(
          1,
          memberInformation.gender.length
        )}`
      : "";
  const pronouns =
    memberInformation && memberInformation.pronouns
      ? `${memberInformation.pronouns[0].toUpperCase()}${memberInformation.pronouns.substring(
          1,
          memberInformation.pronouns.length
        )}`
      : "";

  useEffect(() => {
    let data = [...fields];
    kitsSelectedToBeOrdered &&
      kitsSelectedToBeOrdered.forEach((uuid) => {
        let [order] = testKitsGLIsCurrentlyOffering
          ? testKitsGLIsCurrentlyOffering.filter(
              (test) => test.test_uuid === uuid
            )
          : [];
        if (order) {
          data.push({
            name: order.test_name,
            value: `${order.test_name}`
          });
          data.push({
            name: "consent",
            value: "Informed Consent Acknowledged",
            variant: "deprecated_body2",
            style: { marginTop: "-20px", fontStyle: "italic" }
          });
        } else {
          return null;
        }
      });
    setFields(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackToKitSelectionPage = () => {
    history.push(PATHS.ORDER_KIT_SELECTION);
  };

  const formSections = [
    {
      title: "Test Kit information",
      handleBackTo: handleBackToKitSelectionPage,
      fields: fields,
      subFields: fields
    },
    {
      title: "Member information",
      handleBackTo: onBackToMemberInfo,
      fields: [
        { name: "name", value: fullName, label: "Name" },
        {
          name: "sexAssignedAtBirth",
          value: sexAssignedAtBirth,
          label: "Sex assigned at birth"
        },
        { name: "gender", value: gender, label: "Gender" },
        { name: "pronouns", value: pronouns, label: "Pronouns" },
        {
          name: "address",
          value: `${memberInformation.addressLine1}${
            memberInformation.addressLine2
              ? `, ${memberInformation.addressLine2}`
              : ""
          }`
        },
        {
          name: "cityAddress",
          value: `${memberInformation.city}, ${memberInformation.state} ${memberInformation.postalCode}`,
          style: { marginTop: "-20px" }
        },
        {
          name: "country",
          value: memberInformation.country,
          style: { marginTop: "-20px" }
        }
      ]
    }
  ];

  const handleRequest = async () => {
    setIsProccesing(true);
    dispatch(
      setUuidsOfKitsJustOrderedToBeShownOnConclusionScreen(
        kitsSelectedToBeOrdered
      )
    );
    const memberPersonalInfo = (({
      email,
      dob,
      firstName,
      middleName,
      lastName,
      gender,
      phone,
      preferredName,
      pronouns,
      sexAssignedAtBirth,
      suffix
    }) => ({
      email,
      dob,
      firstName,
      middleName,
      lastName,
      gender,
      phoneNumber: phone?.slice(2),
      countryCode: phone?.slice(0, 2),
      preferredName,
      pronouns,
      sexAssignedAtBirth,
      suffix
    }))(memberInformation);
    const shippingInformation = (({
      addressLine1,
      addressLine2,
      city,
      country,
      postalCode,
      state
    }) => ({
      addressLine1,
      addressLine2,
      city,
      country,
      postalCode,
      state
    }))(memberInformation);

    const newlyOrderedKits = await Promise.all(
      kitsSelectedToBeOrdered.map(async (kitUUIDToOrder) => {
        const currentTestInfo =
          testKitsGLIsCurrentlyOfferingMap?.[kitUUIDToOrder] || {};
        const { test_type: currentTestType } = currentTestInfo;

        try {
          const response = await requestOrder({
            ...(currentTestType && { testType: currentTestType }),
            ...(!currentTestType &&
              kitUUIDToOrder && { testUuid: kitUUIDToOrder }),
            memberData: memberPersonalInfo,
            shippingAddress: shippingInformation
          });

          if (response.status === 201) {
            Mixpanel.track(`Kit order ${kitUUIDToOrder} successful`);
            dispatch(
              openGLSnackbar({
                variant: "success",
                header: "Success",
                subText: "The kit was requested successfully!"
              })
            );
            return {
              description: {
                name: TEST_TYPE_BY_UUID[kitUUIDToOrder],
                uuid: kitUUIDToOrder
              },
              documentIds: [],
              glOrderId: `${kitUUIDToOrder}-temp`,
              status: "active"
            };
          } else {
            Mixpanel.track(`Kit order ${kitUUIDToOrder} failed`);
            dispatch(
              openGLSnackbar({
                variant: "error",
                header: true,
                subText:
                  "Sorry, your submission failed. Please try again, and reach out to us if you keep having trouble."
              })
            );
            return null; // Return null in case of failure to filter it later
          }
        } catch (error) {
          setIsProccesing(false);
          let errorDescription = "";

          if (error.response.status === 409) {
            errorDescription =
              "The email you provided cannot be used by our clinical partner. Please edit your order to provide another email address. If you are having problems, please contact us.";
          } else {
            errorDescription =
              "The secure network connection between Genomic Life and our partner is not accepting order data. Please click to try again or contact us and we will manually process your test kit order.";
          }
          dispatch(
            openGLSnackbar({
              variant: "error",
              header: "We're sorry...",
              subText: errorDescription
            })
          );
          return null; // Return null in case of an error to filter it later
        }
      })
    );

    // Filter out any null entries (failed orders). Can't seem to make reduce
    //  wait properly.
    const validOrderedKits = newlyOrderedKits.filter((kit) => kit !== null);

    // Proceed with the dispatch calls once all the orders have been processed
    dispatch(
      setExistingOrders({
        data: { orders: [...previouslyExistingKitOrders, ...validOrderedKits] }
      })
    );
    dispatch(setKitsSelectedToBeOrdered([]));
    setIsProccesing(false);
    history.push(PATHS.ORDER_KIT_CONFIRMATION);
  };

  return (
    <div className={classes.root}>
      <LoadingPage
        isProccesing={isProccesing}
        loadingText={"Processing your request ..."}
      />
      <Grid container>
        <Grid
          container
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className={classes.body}
        >
          <Grid
            container
            xs={10}
            sm={12}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="deprecated_h5"
              align="center"
              className={classes.requestConfirmationTitle}
            >
              <Translate text="Almost done! Please review the following." />
            </Typography>
          </Grid>
          <Grid item xs={11} sm={9} md={7} lg={4} alignItems="stretch">
            <FormSummary formSections={formSections} />
          </Grid>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={11} sm={9} md={7} lg={4}>
              <Grid
                style={{
                  marginTop: buttonPadding,
                  marginBottom: buttonPadding
                }}
                container
                justifyContent={"center"}
              >
                <Button
                  onClick={handleRequest}
                  variant="contained"
                  color="primary"
                >
                  <Translate text="COMPLETE KIT REQUEST" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderKitRequestConfirmation;
